import React from "react";
import CosTableOfContentsEntry from "./CosTableOfContentsEntry";
import CosTableOfContentsOverlay from "./CosTableOfContentsOverlay";
import "../../css/cos_table_of_contents.css.scss";
import { Link, withRouter } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class CosTableOfContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayVisible: false,
      colorSettings: null,
      loading: true
    };
  }

  componentDidMount() {
    this.fetchSettingsData();
  }

  fetchSettingsData() {
    fetch("/settings/get_color_settings")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      this.setState({ colorSettings: data.settings, loading: false });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateChaptersHierarchy() {
    let chapters = {};

    this.props.chapters.forEach((chapter) => {
      if (chapter.chapter_number != null) {
        let chapterLevel = chapter.chapter_number.split(".").length;

        // Root level
        if (chapterLevel == 1) {
          chapters[chapter.chapter_number] = {
            number: chapter.chapter_number,
            title: chapter.chapter_title,
            chapters: {},
          };
        }

        // Second level
        if (chapterLevel == 2) {
          let rootChapterNumber = chapter.chapter_number.split(".")[0];
          if (chapters[rootChapterNumber] != null) {
            chapters[rootChapterNumber]["chapters"][chapter.chapter_number] = {
              number: chapter.chapter_number,
              title: chapter.chapter_title,
              chapters: {},
            };
          }
        }

        // Third level
        if (chapterLevel == 3) {
          let rootChapterNumber = chapter.chapter_number.split(".")[0];
          let levelOneChapterNumber = chapter.chapter_number
            .split(".")
            .slice(0, 2)
            .join(".");
          if (
            chapters[rootChapterNumber] != null &&
            chapters[rootChapterNumber]["chapters"][levelOneChapterNumber] !=
              null
          ) {
            chapters[rootChapterNumber]["chapters"][levelOneChapterNumber][
              "chapters"
            ][chapter.chapter_number] = {
              number: chapter.chapter_number,
              title: chapter.chapter_title,
            };
          }
        }
      }
    });
    return chapters;
  }

  toggleOpenCloseOverlay() {
    this.setState({ overlayVisible: !this.state.overlayVisible });
    return false;
  }

  menuClick(event) {
    const anchor = event.target.closest("a");
    const link = anchor.getAttribute("href");
    this.props.history.push(link);

    var ulElement = event.target.closest("ul.level-1");
    if (ulElement == null) {
      return;
    }

    // Force closing
    ulElement.style.maxWidth = "42px";

    // Delete max-width style to re-enable menu auto opening
    setTimeout(function () {
      ulElement.style.maxWidth = "";
    }, 250);
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    const chaptersHierarchy = this.generateChaptersHierarchy();
    const navbarColor = this.state.colorSettings && this.state.colorSettings.find(item => item.key == "navbar-color");
    const chapterLevelThreeColor = this.state.colorSettings && this.state.colorSettings.find(item => item.key == "chapter-level-3-color");
    const chapterLevelFourColor = this.state.colorSettings && this.state.colorSettings.find(item => item.key == "chapter-level-4-color");

    document.documentElement.style.setProperty('--navbar-color', navbarColor.value);
    document.documentElement.style.setProperty('--chapter-level-3-color', chapterLevelThreeColor.value);
    document.documentElement.style.setProperty('--chapter-level-4-color', chapterLevelFourColor.value);

    const renderTooltip = (props) => {
      let message = "";
      if (props.popper.state) {
        message = props.popper.state.options.title;
      }
      return (
        <Tooltip id="button-tooltip" {...props}>
          {message}
        </Tooltip>
      );
    };
    const chapters = Object.keys(chaptersHierarchy)
      .filter((chapter) => chapter != "0")
      .map((chapterNumber, index) => (
        <CosTableOfContentsEntry
          key={index}
          index={index}
          chapter={chaptersHierarchy[chapterNumber]}
          level={1}
        />
      ));
    let topButton = (
      <div className="toc_button">
        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 250 }}
          popperConfig={{ title: "Table of contents" }}
          overlay={renderTooltip}
        >
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              this.toggleOpenCloseOverlay();
            }}
          >
            <i className="fa fa-list"></i>
          </a>
        </OverlayTrigger>
      </div>
    );
    let guidelinesButton = (
      <div className="toc_button">
        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 250 }}
          popperConfig={{ title: "Guidelines" }}
          overlay={renderTooltip}
        >
          <Link
            to={{
              pathname: "/guidelines",
            }}
          >
            <i className="fa fa-book"></i>
          </Link>
        </OverlayTrigger>
      </div>
    );
    let leafletsButton = (
      <div className="toc_button">
        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 250 }}
          popperConfig={{ title: "Leaflets" }}
          overlay={renderTooltip}
        >
          <Link
            to={{
              pathname: "/technical_leaflets",
            }}
          >
            <i className="fa fa-keyboard-o"></i>
          </Link>
        </OverlayTrigger>
      </div>
    );
    const printButton = (
      <div className="toc_button">
        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 250 }}
          popperConfig={{ title: "Print" }}
          overlay={renderTooltip}
        >
          <button
            className="btn btn-transparent btn-m text-white print-btn-hover-effect print-btn-squared"
            onClick={() => window.print()}
          >
            <i className="glyphicon glyphicon-print"></i>
          </button>
        </OverlayTrigger>
      </div>
    );

    return (
      <>
        <nav className="cos-table-of-contents do-not-print">
          <div className="bar-background"/>
          {topButton}
          <ul
            className="level-1"
            onClick={(event) => {
              event.preventDefault();
              this.menuClick(event);
            }}
          >
            {chapters}
            {guidelinesButton}
            {leafletsButton}
            {printButton}
          </ul>
        </nav>
        {this.state.overlayVisible && (
          <CosTableOfContentsOverlay
            overlayParent={this}
            chapters={chaptersHierarchy}
          />
        )}
      </>
    );
  }
}

export default withRouter(CosTableOfContents);
